// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: Arial, sans-serif;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 12px;
    text-align: center;
    border: 1px solid #0a0909;
}
th {
    background-color:  hsl(208.02deg 100% 67.25%);
    color: #fff;
}
.header {
    margin-top: 0px;
    margin-bottom: 0px;
    background: #f7f7f7;
    padding: 10px 0;
    }

    .d-flex.justify-content-center a.btn-primary {
        background-color: hsl(338.43deg 100% 70%)!important;
        border-color: hsl(338.43deg 100% 70%) !important;
    }
.logoout button {
    background: hsl(338.43deg 100% 70%);
    border: 0;
    border-radius: 5px;
    padding: 10px 40px;
    font-size: 18px;
    font-weight: 500;
    color: #ffff;
    margin-left: 80%;
    }
 
    .bg_new .row {
        align-items: center;
        }
        .backto{
    text-decoration: none;
    color: #fff;
}
.table-fill td{
    /* background-color: #f5f5f5; */
    border: 1px solid #000;
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/profileStyle.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;AAC7B;AACA;IACI,6CAA6C;IAC7C,WAAW;AACf;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf;;IAEA;QACI,mDAAmD;QACnD,gDAAgD;IACpD;AACJ;IACI,mCAAmC;IACnC,SAAS;IACT,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB;;IAEA;QACI,mBAAmB;QACnB;QACA;IACJ,qBAAqB;IACrB,WAAW;AACf;AACA;IACI,+BAA+B;IAC/B,sBAAsB;AAC1B","sourcesContent":["body {\n    font-family: Arial, sans-serif;\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n}\n\nth, td {\n    padding: 12px;\n    text-align: center;\n    border: 1px solid #0a0909;\n}\nth {\n    background-color:  hsl(208.02deg 100% 67.25%);\n    color: #fff;\n}\n.header {\n    margin-top: 0px;\n    margin-bottom: 0px;\n    background: #f7f7f7;\n    padding: 10px 0;\n    }\n\n    .d-flex.justify-content-center a.btn-primary {\n        background-color: hsl(338.43deg 100% 70%)!important;\n        border-color: hsl(338.43deg 100% 70%) !important;\n    }\n.logoout button {\n    background: hsl(338.43deg 100% 70%);\n    border: 0;\n    border-radius: 5px;\n    padding: 10px 40px;\n    font-size: 18px;\n    font-weight: 500;\n    color: #ffff;\n    margin-left: 80%;\n    }\n \n    .bg_new .row {\n        align-items: center;\n        }\n        .backto{\n    text-decoration: none;\n    color: #fff;\n}\n.table-fill td{\n    /* background-color: #f5f5f5; */\n    border: 1px solid #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
