// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chip{
  margin:auto;
  font-size:10px;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  color: #ffffff;
  position:relative;
  box-sizing:border-box;
  width:20px;
  height:20px;
  background:#555;
  border-radius:50%;
  border:1px dashed white;
  backface-visibility:hidden;
  box-shadow:0 0 0 .2em #fff;
  cursor: pointer;
  z-index: 9999;
}

.no-chip {
  display: none;
}

.chip.red{
  background:#DD4631;
}

.chip.blue{
  background:#317DDD;
}


.chip:hover{
  transform:rotate(90deg);
  color: yellow;
}
`, "",{"version":3,"sources":["webpack://./src/Components/chips/Chips.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EAGjB,qBAAqB;EACrB,UAAU;EACV,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,0BAA0B;EAC1B,0BAA0B;EAC1B,eAAe;EACf,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;;AAGA;EACE,uBAAuB;EACvB,aAAa;AACf","sourcesContent":[".chip{\n  margin:auto;\n  font-size:10px;\n  font-weight: bold;\n  text-align: center;\n  line-height: 20px;\n  color: #ffffff;\n  position:relative;\n  -webkit-box-sizing:border-box;\n  -moz-box-sizing:border-box;\n  box-sizing:border-box;\n  width:20px;\n  height:20px;\n  background:#555;\n  border-radius:50%;\n  border:1px dashed white;\n  backface-visibility:hidden;\n  box-shadow:0 0 0 .2em #fff;\n  cursor: pointer;\n  z-index: 9999;\n}\n\n.no-chip {\n  display: none;\n}\n\n.chip.red{\n  background:#DD4631;\n}\n\n.chip.blue{\n  background:#317DDD;\n}\n\n\n.chip:hover{\n  transform:rotate(90deg);\n  color: yellow;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
