// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/image/roulette-bg.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/image/button-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Weel only styles */

.roulette-canvas {
  margin: -8px;;
}

.roulette-container  {
  width: 400px;
  height: 400px;
  position: relative;
  left:5px;
  top:5px;
  background: #000000 url(${___CSS_LOADER_URL_REPLACEMENT_0___}) top center no-repeat;
  background-size: contain;
  border-radius: 50%;
  margin: 0 auto;
}

.spin-button {
  border-radius: 50%;
  border: 5px solid #fff294;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 92px;
  left: 92px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) center center no-repeat;
  background-size: cover;
  color: #121212;
}


.text-black-ish {
  color: #121212;
}

.blinky-number {
  font-family: 'Anton', sans-serif;
  font-weight: 500;
  animation: blinky-number 1s ease infinite;
  -moz-animation: blinky-number 1s ease infinite;
  -webkit-animation: blinky-number 1s ease infinite;
}

@keyframes blinky-number {
  0%,
  100% {
    color: #ff073a;
  }
  50% {
    color: #fff294;
  }
}

#spinn{
  background-color: #000000 !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/weel/Weel.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,gFAAkF;EAClF,wBAAwB;EACxB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,2EAA2E;EAC3E,sBAAsB;EACtB,cAAc;AAChB;;;AAGA;EACE,cAAc;AAChB;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,yCAAyC;EACzC,8CAA8C;EAC9C,iDAAiD;AACnD;;AAEA;EACE;;IAEE,cAAc;EAChB;EACA;IACE,cAAc;EAChB;AACF;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["/* Weel only styles */\n\n.roulette-canvas {\n  margin: -8px;;\n}\n\n.roulette-container  {\n  width: 400px;\n  height: 400px;\n  position: relative;\n  left:5px;\n  top:5px;\n  background: #000000 url(\"../../assets/image/roulette-bg.png\") top center no-repeat;\n  background-size: contain;\n  border-radius: 50%;\n  margin: 0 auto;\n}\n\n.spin-button {\n  border-radius: 50%;\n  border: 5px solid #fff294;\n  width: 200px;\n  height: 200px;\n  position: absolute;\n  top: 92px;\n  left: 92px;\n  background: url(\"../../assets/image/button-bg.png\") center center no-repeat;\n  background-size: cover;\n  color: #121212;\n}\n\n\n.text-black-ish {\n  color: #121212;\n}\n\n.blinky-number {\n  font-family: 'Anton', sans-serif;\n  font-weight: 500;\n  animation: blinky-number 1s ease infinite;\n  -moz-animation: blinky-number 1s ease infinite;\n  -webkit-animation: blinky-number 1s ease infinite;\n}\n\n@keyframes blinky-number {\n  0%,\n  100% {\n    color: #ff073a;\n  }\n  50% {\n    color: #fff294;\n  }\n}\n\n#spinn{\n  background-color: #000000 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
