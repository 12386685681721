// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider_card1 ul li {
    float: left;
    margin-left: 4px;
    list-style: none;
  }
  .slider_card1 ul li img {
    width: 20px;
  }
  .slick-slide img {
    display: block;
    padding: 4px;
  }
  .slider_card1 ul {
    padding: 0;
  }
  .play1 p {
    color: #fff;
    text-align: start;
    font-size: 16px;
    font-weight: 500;
  }
  .slider_card1 {
    position: absolute;
    top: 0px;
    background: #0000006b;
    padding: 5px;
    width: 125px;
}
.play1 img {
  margin-right: 5px;
}
.slider_time{
    position: absolute;
    bottom: 0px;
    right: 0;
    background: #0000006b;
    padding: 5px;
    width: 60px;
  }
  .slider_time p{
    padding: 5px;
    font-size: 25px !important;
    font-weight: 600;
    color: #fff;
  }`, "",{"version":3,"sources":["webpack://./src/pages/teenpatti-t20/teenpatti-t20Style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;EAClB;EACA;IACE,WAAW;EACb;EACA;IACE,cAAc;IACd,YAAY;EACd;EACA;IACE,UAAU;EACZ;EACA;IACE,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,kBAAkB;IAClB,QAAQ;IACR,qBAAqB;IACrB,YAAY;IACZ,YAAY;AAChB;AACA;EACE,iBAAiB;AACnB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,qBAAqB;IACrB,YAAY;IACZ,WAAW;EACb;EACA;IACE,YAAY;IACZ,0BAA0B;IAC1B,gBAAgB;IAChB,WAAW;EACb","sourcesContent":[".slider_card1 ul li {\n    float: left;\n    margin-left: 4px;\n    list-style: none;\n  }\n  .slider_card1 ul li img {\n    width: 20px;\n  }\n  .slick-slide img {\n    display: block;\n    padding: 4px;\n  }\n  .slider_card1 ul {\n    padding: 0;\n  }\n  .play1 p {\n    color: #fff;\n    text-align: start;\n    font-size: 16px;\n    font-weight: 500;\n  }\n  .slider_card1 {\n    position: absolute;\n    top: 0px;\n    background: #0000006b;\n    padding: 5px;\n    width: 125px;\n}\n.play1 img {\n  margin-right: 5px;\n}\n.slider_time{\n    position: absolute;\n    bottom: 0px;\n    right: 0;\n    background: #0000006b;\n    padding: 5px;\n    width: 60px;\n  }\n  .slider_time p{\n    padding: 5px;\n    font-size: 25px !important;\n    font-weight: 600;\n    color: #fff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
