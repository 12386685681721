// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider_time{
    position: absolute;
    bottom: 0px;
    right: 0;
    background: #0000006b;
    padding: 5px;
    width: 80px !important;
  }
  .slider_time p{
    padding: 5px;
    font-size: 25px !important;
    font-weight: 600;
    color: #fff;
  }
  .margeing img {
    margin-right: 5px;
  }
  .dt_tiger .ader_b_box p {
    font-weight: 700;
    line-height: 3;
}
.slider_cardamar {
  width: 90px;
}`, "",{"version":3,"sources":["webpack://./src/pages/dt202/dt202Style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,qBAAqB;IACrB,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE,YAAY;IACZ,0BAA0B;IAC1B,gBAAgB;IAChB,WAAW;EACb;EACA;IACE,iBAAiB;EACnB;EACA;IACE,gBAAgB;IAChB,cAAc;AAClB;AACA;EACE,WAAW;AACb","sourcesContent":[".slider_time{\n    position: absolute;\n    bottom: 0px;\n    right: 0;\n    background: #0000006b;\n    padding: 5px;\n    width: 80px !important;\n  }\n  .slider_time p{\n    padding: 5px;\n    font-size: 25px !important;\n    font-weight: 600;\n    color: #fff;\n  }\n  .margeing img {\n    margin-right: 5px;\n  }\n  .dt_tiger .ader_b_box p {\n    font-weight: 700;\n    line-height: 3;\n}\n.slider_cardamar {\n  width: 90px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
