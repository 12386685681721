// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/image/andarbahar2.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ander_bhar2 .image_casino{
    height: 400px;
    width: 100%;
    background-size: cover;
    background-image:    linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), 
    url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    
}
.ander_bhar2 .image_casino .slider_card {
    position: absolute;
    top: 100px;
    background: #0000006b;
    padding: 5px;
    width: 570px;
    left: 0;
    right: 0;
    margin: auto;
}

.ander_bhar2 .justyfy_card {
    justify-content: center;
}
.ander_bhar2 .card53 {
    width: 45px;
    height: 50px;
}

.ander_bhar2 .slick-slide img {
    display: block;
    padding: 0px;
    width: 45px;
    height: 50px;
}

@media (max-width: 768px) {
    .ander_bhar2 .image_casino .slider_card {
        position: absolute;
        top: 90px;
        background: #0000006b;
        padding: 5px;
        width: 360px;
        left: 0;
        right: 0;
        margin: auto;
    }
}

/* ============================= */

`, "",{"version":3,"sources":["webpack://./src/pages/anderbahar2/andarbaharStyle2.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB;2CACoC;;AAExC;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,OAAO;IACP,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,kBAAkB;QAClB,SAAS;QACT,qBAAqB;QACrB,YAAY;QACZ,YAAY;QACZ,OAAO;QACP,QAAQ;QACR,YAAY;IAChB;AACJ;;AAEA,kCAAkC","sourcesContent":[".ander_bhar2 .image_casino{\n    height: 400px;\n    width: 100%;\n    background-size: cover;\n    background-image:    linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), \n    url('/public/image/andarbahar2.jpg');\n    \n}\n.ander_bhar2 .image_casino .slider_card {\n    position: absolute;\n    top: 100px;\n    background: #0000006b;\n    padding: 5px;\n    width: 570px;\n    left: 0;\n    right: 0;\n    margin: auto;\n}\n\n.ander_bhar2 .justyfy_card {\n    justify-content: center;\n}\n.ander_bhar2 .card53 {\n    width: 45px;\n    height: 50px;\n}\n\n.ander_bhar2 .slick-slide img {\n    display: block;\n    padding: 0px;\n    width: 45px;\n    height: 50px;\n}\n\n@media (max-width: 768px) {\n    .ander_bhar2 .image_casino .slider_card {\n        position: absolute;\n        top: 90px;\n        background: #0000006b;\n        padding: 5px;\n        width: 360px;\n        left: 0;\n        right: 0;\n        margin: auto;\n    }\n}\n\n/* ============================= */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
