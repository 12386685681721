// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  .video-container {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 1px 8px 14px #00000059;
    border-radius: 20px;
  }
  
  .video-container img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 20px;
    
  }
  
  .video-container:hover img {
    transform: scale(1.1);
    border-radius: 20px;
  }
  
  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
.box-price button {
    background: #212529;
    border-color: #212529;
    color: #fff;
    padding: 7px 20px;
}
.box-price button:hover {
  background: #97173a;
  border-color: #97173a;
  color: #fff;
  padding: 7px 20px;
}
.main-price p {
  font-weight: 600;
}
body {
  background: hsl(0deg 0% 100%);
}`, "",{"version":3,"sources":["webpack://./src/pages/games/gamesStyle.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,+BAA+B;EACjC;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;EACd;EACA;IACE,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,kCAAkC;IAClC,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,+BAA+B;IAC/B,mBAAmB;;EAErB;;EAEA;IACE,qBAAqB;IACrB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;EACd;AACF;IACI,mBAAmB;IACnB,qBAAqB;IACrB,WAAW;IACX,iBAAiB;AACrB;AACA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".video-container {\n    position: relative;\n    cursor: pointer;\n    transition: transform 0.3s ease;\n  }\n  \n  .play-icon {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color: white;\n  }\n  .video-container {\n    position: relative;\n    cursor: pointer;\n    overflow: hidden;\n    box-shadow: 1px 8px 14px #00000059;\n    border-radius: 20px;\n  }\n  \n  .video-container img {\n    width: 100%;\n    height: auto;\n    transition: transform 0.3s ease;\n    border-radius: 20px;\n    \n  }\n  \n  .video-container:hover img {\n    transform: scale(1.1);\n    border-radius: 20px;\n  }\n  \n  .play-icon {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color: white;\n  }\n.box-price button {\n    background: #212529;\n    border-color: #212529;\n    color: #fff;\n    padding: 7px 20px;\n}\n.box-price button:hover {\n  background: #97173a;\n  border-color: #97173a;\n  color: #fff;\n  padding: 7px 20px;\n}\n.main-price p {\n  font-weight: 600;\n}\nbody {\n  background: hsl(0deg 0% 100%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
